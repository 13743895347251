<template>
  <div class="widget-inner">
    <ul class="tabs">
      <li :class="{'active': chart_mode === 'shoutout_count'}">
        <a href="#" @click.prevent="chart_mode = 'shoutout_count';">Shoutouts Sent</a>
      </li>
      <li :class="{'active': chart_mode === 'engagement'}">
        <a href="#" @click.prevent="chart_mode = 'engagement';">Engaged Receivers</a>
      </li>
    </ul>
    <component :is="chart_mode === 'shoutout_count' ? 'Chart' : 'EngagementChart'" class="chart-elem" :width="null" :height="null" v-if="!!data" :key="`chart_${chart_mode}`" :data="chart_data" />
  </div>
</template>

<script>
import Chart from './_chart.vue'
import EngagementChart from './_engagementChart.vue'

export default {
  props: ['data'],
  components: {
    Chart,
    EngagementChart
  },
  computed: {
    chart_data() {
      return this.chart_mode === 'shoutout_count' ? this.data.segment_data : this.data.engagement_data;
    }
  },
  data() {
    return {
      chart_mode: 'shoutout_count'
    }
  },
  mounted() {
    this.$emit('loaded')
  }
}
</script>

<style lang="scss" scoped>
.chart-elem {
  position: relative;
}

.tabs {
  display: flex;
  padding: 0;
  margin: 0 0 10px;
  justify-content: center;

  > li {
    display: block;
    padding: 0 15px;

    > a {
      display: block;
      color: $muted-text;
      font-weight: 700;
      border-radius: 100em;
      padding: 5px 12px;
      line-height: 14px;
      font-size: 14px;
      text-decoration: none;
    }

    &.active {
      > a {
        background: $light;
      }
    }
  }
}
</style>